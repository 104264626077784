/* Typography styles for luxury watch marketplace */

/* Removed manual font import since we're using Next.js font system */

/* Heading styles */
h1,
h2,
h3,
h4,
h5,
h6,
.font-serif {
  font-family: var(--font-playfair), serif;
}

body,
p,
input,
button,
.font-sans {
  font-family: var(--font-inter), sans-serif;
}

/* Dashboard font styles */
.dashboard-font {
  font-family: system-ui, sans-serif;
}

.dashboard-mono {
  font-family: monospace;
}

/* Typography scale */
.display-large {
  font-family: var(--font-playfair), serif;
  font-size: 3.5rem;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.display-medium {
  font-family: var(--font-playfair), serif;
  font-size: 2.75rem;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.display-small {
  font-family: var(--font-playfair), serif;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 600;
}

.heading-large {
  font-family: var(--font-playfair), serif;
  font-size: 1.75rem;
  line-height: 1.3;
  font-weight: 500;
}

.heading-medium {
  font-family: var(--font-playfair), serif;
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 500;
}

.heading-small {
  font-family: var(--font-playfair), serif;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 500;
}

.body-large {
  font-family: var(--font-inter), sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 400;
}

.body-medium {
  font-family: var(--font-inter), sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

.body-small {
  font-family: var(--font-inter), sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 400;
}

.caption {
  font-family: var(--font-inter), sans-serif;
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.01em;
}

/* Luxury-specific styles */
.luxury-quote {
  font-family: var(--font-playfair), serif;
  font-style: italic;
  font-weight: 400;
  border-left: 2px solid hsl(var(--primary));
  padding-left: 1rem;
}

.label-elegant {
  font-family: var(--font-inter), sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.price-display {
  font-family: var(--font-playfair), serif;
  font-weight: 500;
}

/* Add gold underline effect for luxury headings */
.luxury-underline {
  position: relative;
  display: inline-block;
}

.luxury-underline:after {
  content: '';
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  width: 2rem;
  height: 2px;
  background-color: hsl(var(--primary));
}
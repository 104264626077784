/* font-fix.css - Force font application at a higher specificity */
html {
  font-family:
    var(--font-inter),
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif !important;
}

body,
p,
span,
div,
button,
input,
textarea,
select,
option,
li,
a {
  font-family:
    var(--font-inter),
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-playfair), Georgia, 'Times New Roman', serif !important;
}

/* Classes for direct application when needed */
.font-inter {
  font-family:
    var(--font-inter),
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif !important;
}

.font-playfair {
  font-family: var(--font-playfair), Georgia, 'Times New Roman', serif !important;
}
